import { Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function NoData() {
  return (
    <VStack flex={1} justify={"center"} p={5}>
      <Image src="/vectors/noData.svg" w={"100%"} maxW={"400px"} />

      <Text fontSize={18} fontWeight={500}>
        Tidak Ada Data
      </Text>
    </VStack>
  );
}
