import { useColorModeValue } from "@chakra-ui/react";

export default function useColors(color: string) {
  const primary = useColorModeValue("p.500", "p.300");

  switch (color) {
    case "primary":
      return primary;
  }
}
