import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Categories } from "../const/interfaces";
import cr from "../lib/request";
import ComponentSpinner from "./ComponentSpinner";

export default function CategoriesDropdown() {
  const [data, setData] = useState<Categories[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryQuery = params.get("category") || "";
  const onCategoryClick = (categoryId: string): void => {
    const params = new URLSearchParams(location.search);
    params.set("category", categoryId);
    navigate(`?${params.toString()}`);
  };

  useEffect(() => {
    setLoading(true);
    const url = `api/blog/allcategories`;
    cr.get(url)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Menu>
      <MenuButton
        as={Button}
        className="btn-solid"
        rightIcon={<Icon as={CaretDown} />}
        textAlign={"left"}
        flexShrink={0}
      >
        {data?.find((category) => parseInt(categoryQuery) === category.id)
          ?.name || "Semua Kategori"}
      </MenuButton>

      <MenuList>
        <MenuItem
          onClick={() => {
            onCategoryClick("");
          }}
        >
          Semua Kategori
        </MenuItem>

        {!loading && data ? (
          data.map((category, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                onCategoryClick(category.id.toString());
              }}
            >
              {category.name}
            </MenuItem>
          ))
        ) : (
          <ComponentSpinner />
        )}
      </MenuList>
    </Menu>
  );
}
