import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  ArrowDown,
  ArrowUpRight,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BlogPostCard from "../components/BlogPostCard";
import Container from "../components/Container";
import KubuSpinner from "../components/KubuSpinner";
import NavContainer from "../components/NavContainer";
import useColors from "../const/colors";
import { BlogPost, LandingData } from "../const/interfaces";
import { textSize, textSizeSm } from "../const/sizes";
import formatDate from "../lib/formatDate";
import cr from "../lib/request";
import useScreenWidth from "../lib/useGetScreenWidth";

export default function Landing() {
  const sw = useScreenWidth();
  const [data, setData] = useState<LandingData | null>(null);
  const [blogList, setBlogList] = useState<BlogPost[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("search") || "";
    setSearchQuery(query);
  }, [location.search]);

  const searchOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    const params = new URLSearchParams(location.search);
    params.set("search", query);
    navigate(`?${params.toString()}`);
  };

  useEffect(() => {
    setLoading(true);
    const url = `api/blog/post/getlandingdata`;

    cr.get(url)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data.data);
          setBlogList(r.data.data.bloglist);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [offset, setOffset] = useState<number>(6);
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    const url = `api/blog/post/loadmore`;
    const payload = {
      offset: offset,
    };

    cr.post(url, payload)
      .then((r) => {
        if (r.status === 200) {
          setOffset(r.data.next_offset);
          if (blogList) {
            if (r.data.data.length === 0) {
              toast({
                title: "Sudah tidak ada Blog Post",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            } else {
              setBlogList([...blogList, ...r.data.data]);
            }
          }
        }
      })
      .finally(() => {
        setLoadMoreLoading(false);
      });
  };

  const headingColor = useColorModeValue("white", "#111");
  const primaryTextColor = useColors("primary");

  return !loading && data && blogList ? (
    <NavContainer active={[1]}>
      <VStack
        align={"stretch"}
        py={12}
        flex={1}
        pb={"50px"}
        overflow={"clip"}
        // bg={"var(--divider)"}
      >
        <Container position={"relative"}>
          <Image
            src={"/images/bgHero.svg"}
            position={"absolute"}
            w={"1000px"}
            zIndex={-1}
            transform={"rotate(20deg)"}
            right={"-800px"}
            top={"-500px"}
          />

          <VStack mb={4}>
            <Image
              src={"/images/logo.png"}
              h={"40px"}
              objectFit={"contain"}
              borderRadius={"full"}
            />
          </VStack>
          <Heading fontWeight={900} textAlign={"center"} mb={8}>
            BLOG Kubu.id
          </Heading>

          <HStack mb={12} justify={"center"}>
            <form
              id="searchBlogsFrom"
              onSubmit={(e) => {
                e.preventDefault();
                navigate(`/blog/search?search=${searchQuery}`);
              }}
            >
              <InputGroup mx={"auto"} w={"100%"} maxW={"300px"}>
                <InputLeftElement>
                  <Icon as={MagnifyingGlass} />
                </InputLeftElement>
                <Input
                  placeholder="Cari course"
                  _placeholder={{ opacity: 0.8 }}
                  onChange={searchOnChange}
                  value={searchQuery}
                />
              </InputGroup>
            </form>

            <Button
              type="submit"
              form="searchBlogsFrom"
              colorScheme="p"
              className="clicky"
            >
              Cari
            </Button>
          </HStack>

          <Stack dir={sw < 768 ? "column" : "row"} gap={4}>
            <SimpleGrid columns={[1, null, 2]} gap={4}>
              <Box
                bg={headingColor}
                borderRadius={12}
                p={4}
                border={"1px solid var(--divider3)"}
                // boxShadow={"0 0 5px 0px var(--divider)"}
              >
                <Heading as={"h2"} fontSize={20} mb={4}>
                  Top Posts
                </Heading>

                <VStack align={"strech"}>
                  {data.topposts.map((post, i) => (
                    <HStack
                      key={i}
                      align={"flex-start"}
                      gap={4}
                      borderRadius={8}
                      p={4}
                      _hover={{ bg: "var(--p500a)" }}
                      cursor={"pointer"}
                      as={Link}
                      to={`/blog/${post.id}`}
                    >
                      <Text
                        fontWeight={700}
                        fontSize={24}
                        lineHeight={1.3}
                        color={primaryTextColor}
                      >
                        {i + 1}
                      </Text>

                      <Box>
                        <Heading
                          noOfLines={2}
                          fontSize={textSize}
                          fontWeight={600}
                          lineHeight={1.5}
                          color={primaryTextColor}
                          mb={1}
                        >
                          {post.title}
                        </Heading>
                        <Text opacity={0.5}>{`${
                          post.category_name
                        } - ${formatDate(post.updated_at)}`}</Text>

                        {/* <HStack opacity={0.5} color={primaryTextColor}>
                          <Text>{post.category_name}</Text>
                          <Text>-</Text>
                          <Text>{formatDate(post.updated_at)}</Text>
                        </HStack> */}
                      </Box>
                    </HStack>
                  ))}
                </VStack>
              </Box>

              <Box
                bg={headingColor}
                p={4}
                borderRadius={12}
                border={"1px solid var(--divider3)"}
              >
                <HStack justify={"space-between"} mb={4}>
                  <Heading as={"h2"} fontSize={20}>
                    Kategori
                  </Heading>

                  <Button
                    as={Link}
                    to={"/categories"}
                    colorScheme="ap"
                    variant={"ghost"}
                    size={"sm"}
                    rightIcon={<Icon as={ArrowUpRight} />}
                    color={primaryTextColor}
                  >
                    <Text fontSize={textSizeSm}>Lihat Semua</Text>
                  </Button>
                </HStack>

                <VStack align={"stretch"}>
                  {data.categories.map((category, i) => (
                    <Button
                      key={i}
                      as={Link}
                      to={`/blog/search?category=${category.id}`}
                      colorScheme="ap"
                      variant={"ghost"}
                      justifyContent={"space-between"}
                    >
                      <Text fontWeight={500} color={primaryTextColor}>
                        {category.name}
                      </Text>
                    </Button>
                  ))}
                </VStack>
              </Box>
            </SimpleGrid>

            <SimpleGrid columns={[1, null, 2, 3]} gap={4}>
              {blogList.map((blog, i) => (
                <BlogPostCard key={i} data={blog} />
              ))}
            </SimpleGrid>

            <Button
              variant={"ghost"}
              colorScheme="ap"
              w={"fit-content"}
              mx={"auto"}
              mt={8}
              rightIcon={<Icon as={ArrowDown} />}
              onClick={handleLoadMore}
              className="clicky"
              isLoading={loadMoreLoading}
            >
              Muat lebih Banyak
            </Button>
          </Stack>
        </Container>
      </VStack>
    </NavContainer>
  ) : (
    <KubuSpinner />
  );
}
