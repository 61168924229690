import {
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ArrowDown, MagnifyingGlass } from "@phosphor-icons/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogPostCard from "../components/BlogPostCard";
import ComponentSpinner from "../components/ComponentSpinner";
import Container from "../components/Container";
import KubuSpinner from "../components/KubuSpinner";
import NavContainer from "../components/NavContainer";
import NoData from "../components/NoData";
import { BlogPost } from "../const/interfaces";
import cr from "../lib/request";
import CategoriesDropdown from "../components/CategoriesDropdown";

export default function BlogPostSearch() {
  const [data, setData] = useState<BlogPost[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchQuery, setSearchQuery] = useState<string | null>(
    params.get("search")
  );
  const categoryQuery = params.get("category") || "";

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("search") || "";
    setSearchQuery(query);
  }, [location.search]);

  const searchOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    const params = new URLSearchParams(location.search);
    params.set("search", query);
    navigate(`?${params.toString()}`);
  };
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const submitSearch = () => {
    setSearchLoading(true);
    const url = `api/blog/post/search`;
    const payload = {
      search: searchQuery,
      category_id: parseInt(categoryQuery),
    };
    cr.post(url, payload)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data.data);
          setOffset(r.data.data.length);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        setSearchLoading(false);
      });
  };
  const submitSearchRef = useRef(submitSearch);

  useEffect(() => {
    setLoading(true);

    submitSearchRef.current();
  }, [submitSearchRef.current]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, data, loading]);

  const [offset, setOffset] = useState<number | undefined>(0);
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
  const toast = useToast();
  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    const url = `api/blog/post/searchloadmore`;
    const payload = {
      search: searchQuery,
      category_id: parseInt(categoryQuery),
      offset: offset,
    };
    cr.post(url, payload)
      .then((r) => {
        if (r.status === 200) {
          setOffset(r.data.next_offset);
          if (data) {
            if (r.data.data.length === 0) {
              toast({
                title: "Sudah tidak ada Blog Post",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            } else {
              setData([...data, ...r.data.data]);
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoadMoreLoading(false);
      });
  };

  return !loading ? (
    <NavContainer active={[3]}>
      <VStack
        align={"stretch"}
        py={12}
        flex={1}
        pb={"50px"}
        overflow={"clip"}
        // bg={"var(--divider)"}
      >
        <Container position={"relative"}>
          <Image
            src={"/images/bgHero.svg"}
            position={"absolute"}
            w={"1000px"}
            zIndex={-1}
            transform={"rotate(20deg)"}
            right={"-800px"}
            top={"-500px"}
          />

          <VStack mb={4}>
            <Image
              src={"/images/logo.png"}
              h={"40px"}
              objectFit={"contain"}
              borderRadius={"full"}
            />
          </VStack>
          <Heading fontWeight={900} textAlign={"center"} mb={8}>
            Pencarian Blog Post
          </Heading>

          <HStack mb={12} justify={"center"}>
            <form
              id="searchBlogsFrom"
              onSubmit={(e) => {
                e.preventDefault();
                submitSearch();
              }}
            >
              <InputGroup mx={"auto"} w={"100%"} maxW={"300px"}>
                <InputLeftElement>
                  <Icon as={MagnifyingGlass} />
                </InputLeftElement>
                <Input
                  placeholder="Cari course"
                  _placeholder={{ opacity: 0.8 }}
                  onChange={searchOnChange}
                  value={searchQuery as string}
                />
              </InputGroup>
            </form>

            <CategoriesDropdown />

            <Button
              type="submit"
              form="searchBlogsFrom"
              colorScheme="p"
              className="clicky"
            >
              Cari
            </Button>
          </HStack>

          {searchLoading ? (
            <ComponentSpinner />
          ) : (
            <SimpleGrid columns={[1, null, 2, 3]} gap={4}>
              {!loading &&
                data &&
                data.map((blog, i) => <BlogPostCard key={i} data={blog} />)}
            </SimpleGrid>
          )}

          {!searchLoading && data && data.length === 0 && <NoData />}

          {data && !searchLoading && !loading && (
            <Button
              variant={"ghost"}
              colorScheme="ap"
              w={"fit-content"}
              mx={"auto"}
              mt={8}
              rightIcon={<Icon as={ArrowDown} />}
              onClick={handleLoadMore}
              className="clicky"
              isLoading={loadMoreLoading}
            >
              Muat lebih Banyak
            </Button>
          )}
        </Container>
      </VStack>
    </NavContainer>
  ) : (
    <KubuSpinner />
  );
}
