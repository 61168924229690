import React from "react";
import { BlogPost } from "../const/interfaces";
import {
  Badge,
  Box,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import formatDate from "../lib/formatDate";
import { CirclesFour, Eye } from "@phosphor-icons/react";
import formatNumber from "../lib/formatNumber";
import formatNumberShort from "../lib/formatNumberShort";

type Props = {
  data: BlogPost;
};

export default function BlogPostCard({ data }: Props) {
  return (
    <VStack
      align={"stretch"}
      as={Link}
      to={`/blog/${data.id}`}
      borderRadius={8}
      p={2}
      transition={"200ms"}
      cursor={"pointer"}
      _hover={{
        bg: "var(--divider)",
        // boxShadow: "0 0 0 2px var(--p400)",
      }}
      gap={0}
    >
      <Box
        bgImage={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
        bgSize={"cover"}
        bgPos={"center"}
        h={"200px"}
        borderRadius={8}
        mb={2}
      />

      <VStack p={2} align={"stretch"} flex={1} gap={0}>
        <HStack justify={"space-between"} mb={2}>
          <Badge bg={"var(--divider2)"} pl={3}>
            <HStack>
              <Icon as={CirclesFour} fontSize={14} />
              <Text fontSize={12}>{data.category_name}</Text>
            </HStack>
          </Badge>

          <Tooltip label={`${formatNumber(data.views)} Views`}>
            <HStack opacity={0.5}>
              <Icon as={Eye} fontSize={16} />

              <Text noOfLines={1}>{formatNumberShort(data.views)}</Text>
            </HStack>
          </Tooltip>
        </HStack>

        <Text
          fontSize={[20, null, 22]}
          fontWeight={700}
          noOfLines={2}
          mb={4}
          lineHeight={1.4}
        >
          {data.title}
        </Text>

        <Text noOfLines={2} mb={4} lineHeight={1.4} opacity={0.5}>
          {data.description}
        </Text>

        <HStack justify={"flex-end"} mt={"auto"}>
          <Text>{`${formatDate(data.created_at)}`}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
}
