import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Text,
  VStack,
  Link as ChakraLink,
  Heading,
  Badge,
  useToast,
} from "@chakra-ui/react";
import formatDate from "../lib/formatDate";
import {
  CirclesFour,
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import { BlogPost } from "../const/interfaces";
import KubuSpinner from "../components/KubuSpinner";
import { useNavigate, useParams } from "react-router-dom";
import cr from "../lib/request";
import NavContainer from "../components/NavContainer";
import DOMPurify from "dompurify";
import SharePost from "../components/SharePost";
import RelatedBLogPost from "../components/RelatedBLogPost";
import { Helmet } from "react-helmet-async";

export default function BlogPostPage() {
  const { id } = useParams();
  const [data, setData] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoading(true);
      const url = `api/blog/post/${id}/showpublic`;
      cr.get(url)
        .then((r) => {
          if (r.status === 200) {
            setData(r.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: e.response?.data?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate("/");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, toast, navigate]);

  return (
    <>
      {!loading && data ? (
        <>
          <Helmet>
            <title>{data.title}</title>
            <meta name="title" content={"Kubu Indonesia - " + data.title} />
            <meta name="description" content={data.description} />
            <meta
              name="image"
              content={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
            />
            <meta
              property="og:title"
              content={"Kubu Indonesia - " + data.title}
            />
            <meta property="og:description" content={data.description} />
            <meta
              property="og:image"
              content={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
            />
          </Helmet>

          <NavContainer active={[1]}>
            <VStack align={"stretch"} minH={"100vh"} pb={"50px"}>
              <Container>
                <VStack align={"stretch"} py={5} gap={0}>
                  <VStack my={5}>
                    <Image
                      src={"/images/logo.png"}
                      h={"50px"}
                      borderRadius={"full"}
                      alt={"Logo Kubu.id"}
                    />
                  </VStack>

                  <Text mb={4} textAlign={"center"} opacity={0.8}>
                    {formatDate(data.created_at, {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </Text>

                  <Heading
                    as={"h1"}
                    fontSize={28}
                    fontWeight={700}
                    textAlign={"center"}
                    lineHeight={1.3}
                    mb={8}
                  >
                    {data.title}
                  </Heading>

                  <Image
                    src={`${process.env.REACT_APP_MEDIA_BASE_URL}${data.image}`}
                    borderRadius={8}
                    mb={4}
                    objectFit={"cover"}
                    aspectRatio={"16/10"}
                    alt="Thumbnail"
                  />

                  <SimpleGrid
                    columns={[2]}
                    gap={4}
                    mb={4}
                    alignItems={"center"}
                  >
                    <Badge colorScheme="p" pl={3} w={"fit-content"}>
                      <HStack>
                        <Icon as={CirclesFour} fontSize={14} />
                        <Text fontSize={[10, null, 12]}>
                          {data.category_name}
                        </Text>
                      </HStack>
                    </Badge>

                    <SharePost data={data} />
                  </SimpleGrid>

                  <SimpleGrid columns={[1, null, 2]} gap={4}>
                    <HStack gap={4} align={"flex-start"}>
                      <Box>
                        <Text fontWeight={500}>{"Admin Kubu.id"}</Text>
                        <Text
                          fontSize={"small"}
                          opacity={0.5}
                        >{`Disunting ${formatDate(data.updated_at, {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })}`}</Text>
                      </Box>
                    </HStack>

                    <VStack gap={1} align={["flex-start", null, "flex-end"]}>
                      {/* <Text>follow kami</Text> */}

                      <HStack>
                        <IconButton
                          aria-label="sosmedButton"
                          as={ChakraLink}
                          isExternal
                          href={data?.sosmeds?.[0].content}
                          icon={
                            <Icon
                              as={TwitterLogo}
                              fontSize={[20, null, 22]}
                              weight="fill"
                            />
                          }
                          variant={"ghost"}
                          className="btn"
                          colorScheme="p"
                          borderRadius={"full"}
                        />

                        <IconButton
                          aria-label="sosmedButton"
                          as={ChakraLink}
                          isExternal
                          href={data?.sosmeds?.[1].content}
                          icon={
                            <Icon
                              as={FacebookLogo}
                              fontSize={[20, null, 22]}
                              weight="fill"
                            />
                          }
                          variant={"ghost"}
                          className="btn"
                          colorScheme="p"
                          borderRadius={"full"}
                        />

                        <IconButton
                          aria-label="sosmedButton"
                          as={ChakraLink}
                          isExternal
                          href={data?.sosmeds?.[2].content}
                          icon={
                            <Icon
                              as={InstagramLogo}
                              fontSize={[20, null, 22]}
                              weight="fill"
                            />
                          }
                          variant={"ghost"}
                          className="btn"
                          colorScheme="p"
                          borderRadius={"full"}
                        />

                        <IconButton
                          aria-label="sosmedButton"
                          as={ChakraLink}
                          isExternal
                          href={data?.sosmeds?.[3].content}
                          icon={
                            <Icon
                              as={YoutubeLogo}
                              fontSize={[20, null, 22]}
                              weight="fill"
                            />
                          }
                          variant={"ghost"}
                          className="btn"
                          colorScheme="p"
                          borderRadius={"full"}
                        />
                      </HStack>
                    </VStack>
                  </SimpleGrid>

                  <VStack
                    id="thepost"
                    align={"stretch"}
                    borderTop={"1px solid var(--divider3)"}
                    pt={8}
                    mt={6}
                  >
                    <Text mb={4}>{data.description}</Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data.post),
                      }}
                    />
                  </VStack>
                </VStack>

                <RelatedBLogPost currentBlogPostId={data.id} />
              </Container>
            </VStack>
          </NavContainer>
        </>
      ) : (
        <KubuSpinner />
      )}
    </>
  );
}
