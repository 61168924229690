import React, { useEffect, useState } from "react";
import NavContainer from "../components/NavContainer";
import {
  Button,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import Container from "../components/Container";
import useScreenWidth from "../lib/useGetScreenWidth";
import cr from "../lib/request";
import KubuSpinner from "../components/KubuSpinner";
import { Categories } from "../const/interfaces";
import useColors from "../const/colors";
import { useLocation, useNavigate } from "react-router-dom";

export default function AllBlogPost() {
  const sw = useScreenWidth();
  const [data, setData] = useState<Categories[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const url = `api/blog/allcategories`;

    cr.get(url)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const onCategoryClick = (categoryId: string): void => {
    const params = new URLSearchParams(location.search);
    params.set("category", categoryId);
    navigate(`/blog/search?${params.toString()}`);
  };

  const bg = useColorModeValue("white", "#111");
  const primaryText = useColors("primary");

  return !loading && data ? (
    <NavContainer active={[2]}>
      <VStack
        align={"stretch"}
        py={12}
        flex={1}
        pb={"50px"}
        // bg={"var(--divider)"}
      >
        <Container position={"relative"}>
          <Image
            src={"/images/bgHero.svg"}
            position={"absolute"}
            w={"1000px"}
            zIndex={-1}
            transform={"rotate(20deg)"}
            right={sw < 1000 ? "-700px" : "-670px"}
            top={sw < 1000 ? "-400px" : "-500px"}
          />

          <VStack mb={4}>
            <Image
              src={"/images/logo.png"}
              h={"40px"}
              objectFit={"contain"}
              borderRadius={"full"}
            />
          </VStack>

          <Heading
            fontWeight={900}
            textAlign={"center"}
            mb={8}
            maxW={"400px"}
            mx={"auto"}
          >
            Semua Kategori Blog Kubu.id
          </Heading>

          {/* <VStack mb={12}>
            <InputGroup maxW={"400px"} color={"black"}>
              <InputLeftElement>
                <Icon as={MagnifyingGlass} />
              </InputLeftElement>
              <Input placeholder="Pencarian" w={"100%"} bg={"white"} />
            </InputGroup>
          </VStack> */}

          <SimpleGrid
            columns={sw < 360 ? 1 : [2, null, 3]}
            gap={4}
            bg={bg}
            p={4}
            borderRadius={8}
            border={"1px solid var(--divider3)"}
          >
            {data?.map((category, i) => (
              <Button
                key={i}
                py={6}
                borderRadius={8}
                // border={"1px solid var(--divider3)"}
                color={primaryText}
                cursor={"pointer"}
                variant={"ghost"}
                colorScheme="ap"
                onClick={() => {
                  onCategoryClick(category.id.toString());
                }}
              >
                <Text>{category.name}</Text>
              </Button>
            ))}
          </SimpleGrid>
        </Container>
      </VStack>
    </NavContainer>
  ) : (
    <KubuSpinner />
  );
}
