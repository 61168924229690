import {
  Box,
  HStack,
  Image,
  Link as ChakraLink,
  Text,
  VStack,
  IconButton,
  Icon,
  Link,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import {
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import cr from "../lib/request";

export default function Footer() {
  const [data, setData] = useState<{ id: number; content: string }[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const today = new Date();

  useEffect(() => {
    setLoading(true);
    const url = `api/sosmeds`;
    cr.get(url)
      .then((r) => {
        if (r.status === 200) {
          setData(r.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <VStack gap={0} overflow={"hidden"}>
      {/* <Box h={"100px"} w={"120%"} bg={"p.800"} borderRadius={"100% 100% 0 0"} /> */}

      <VStack
        // bg={"linear-gradient(to bottom left, var(--p800), var(--p700))"}
        bg={"var(--divider)"}
        w={["200%", "180%", "160%", "140%"]}
        pt={"60px"}
        pb={"150px"}
      >
        <VStack w={"100vw"}>
          <Container>
            <HStack gap={3} mb={4}>
              <Image src="/logo.png" h={"32px"} alt="Logo Kubu.id" />
              <Heading as={"h1"} fontWeight={800} fontSize={[26, null, 28]}>
                Blog Kubu.id
              </Heading>
            </HStack>

            <Text
              w={"fit-content"}
              as={ChakraLink}
              href={`emailto:admin@kubu.id`}
              mb={4}
            >
              kontak@kubu.id
            </Text>

            {loading && <Spinner size={"sm"} />}

            {!loading && data && (
              <HStack>
                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={"https://www.facebook.com/kubuindonesia"}
                  icon={
                    <Icon
                      as={FacebookLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />

                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={"https://twitter.com/Kubu_ID"}
                  icon={
                    <Icon
                      as={TwitterLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />

                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={
                    "https://www.instagram.com/kubu.app?igsh=MWpuZmh4Y2xuMXExYQ=="
                  }
                  icon={
                    <Icon
                      as={InstagramLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />

                <IconButton
                  aria-label="sosmedButton"
                  as={Link}
                  isExternal
                  href={"https://www.youtube.com/@aplikasikubu/featured"}
                  icon={
                    <Icon
                      as={YoutubeLogo}
                      fontSize={[20, null, 22]}
                      weight="fill"
                    />
                  }
                  variant={"ghost"}
                  className="btn"
                />
              </HStack>
            )}

            <Box w={"100%"} h={"1px"} bg={"var(--divider)"} my={8} />

            <Text>
              © {today.getFullYear()} Kubu Indonesia. All Rights Reserved.
            </Text>

            <Text
              as={ChakraLink}
              isExternal
              href={"https://kubu.id/index.php/privacy"}
            >
              Privacy Terms & Conditions
            </Text>
          </Container>
        </VStack>
      </VStack>
    </VStack>
  );
}
