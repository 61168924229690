import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Envelope,
  FacebookLogo,
  Link,
  ShareNetwork,
  TwitterLogo,
  WhatsappLogo,
} from "@phosphor-icons/react";
import React, { useRef } from "react";
import { BlogPost } from "../const/interfaces";
import useColors from "../const/colors";

type Props = {
  data: BlogPost;
};

export default function SharePost({ data }: Props) {
  const postUrl = `https://r.blog.kubu.id/api/blog/post/${data.id}/redirect`;
  const postUrl2Copied = `${data.title}\n\nhttps://r.blog.kubu.id/api/blog/post/${data.id}/redirect`;

  const shareToWhatsapp = () => {
    const shareText = encodeURIComponent(`*${data.title}*\n\n${postUrl}`);
    const shareUrl = `whatsapp://send?text=${shareText}`;
    window.location.href = shareUrl;
  };

  const shareToFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      postUrl
    )}`;
    window.open(shareUrl, "_blank");
  };

  const shareToTwitter = () => {
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      postUrl
    )}&text=${encodeURIComponent(data.title)}`;
    window.open(shareUrl, "_blank");
  };

  const shareViaEmail = () => {
    const subject = encodeURIComponent("Lihat postingan blog ini");
    const body = encodeURIComponent(
      `Saya pikir Anda mungkin menganggap postingan blog ini menarik:\n${data.title}\n${postUrl}`
    );
    const shareUrl = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = shareUrl;
  };

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          console.log("Teks berhasil disalin ke clipboard.");
          window.getSelection()?.removeAllRanges();
        })
        .catch((err) => {
          console.error("Tidak dapat menyalin teks ke clipboard: ", err);
        });
    }
  };
  const toast = useToast();

  const shareOptions = [
    { name: "Whatsapp", icon: WhatsappLogo, link: shareToWhatsapp },
    { name: "Facebook", icon: FacebookLogo, link: shareToFacebook },
    { name: "Twitter", icon: TwitterLogo, link: shareToTwitter },
    { name: "Email", icon: Envelope, link: shareViaEmail },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const primaryText = useColors("primary");

  return (
    <>
      <Button
        ml={"auto"}
        w={"fit-content"}
        leftIcon={<Icon as={ShareNetwork} weight="fill" />}
        colorScheme="p"
        variant={"ghost"}
        className="clicky"
        onClick={onOpen}
      >
        Bagikan
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Share</ModalHeader>

          <ModalBody pb={6}>
            <SimpleGrid columns={[2]} gap={4} mb={4}>
              {shareOptions.map((share, i) => (
                <VStack
                  key={i}
                  p={4}
                  bg={"var(--divider)"}
                  borderRadius={8}
                  cursor={"pointer"}
                  onClick={share.link}
                  _hover={{ bg: "var(--divider2)" }}
                >
                  <Icon
                    as={share.icon}
                    fontSize={50}
                    weight="fill"
                    color={primaryText}
                  />
                  <Text color={primaryText} fontWeight={500}>
                    {share.name}
                  </Text>
                </VStack>
              ))}
            </SimpleGrid>

            <textarea
              ref={inputRef}
              defaultValue={postUrl2Copied}
              style={{ position: "absolute", left: "-9999px" }}
            />

            <Button
              className="copyUrlButton"
              colorScheme="p"
              w={"100%"}
              leftIcon={<Icon as={Link} fontSize={20} weight="bold" />}
              fontWeight={600}
              onClick={() => {
                copyToClipboard();
                toast({
                  title: "Tautan berhasil disalin",
                  duration: 5000,
                  isClosable: true,
                });
              }}
            >
              Copy Link URL
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
