import { Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BlogPost } from "../const/interfaces";
import ComponentSpinner from "./ComponentSpinner";
import cr from "../lib/request";
import BlogPostCard from "./BlogPostCard";

type Props = {
  currentBlogPostId: number;
};

export default function RelatedBLogPost({ currentBlogPostId }: Props) {
  const [data, setData] = useState<BlogPost[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentBlogPostId) {
      setLoading(true);
      const url = `api/blog/post/${currentBlogPostId}/relatedpost3`;
      cr.get(url)
        .then((r) => {
          if (r.status === 200) {
            setData(r.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentBlogPostId]);

  return (
    <VStack
      align={"stretch"}
      pt={12}
      mt={6}
      borderTop={"1px solid var(--divider3)"}
    >
      <Heading as={"h2"} fontSize={24} mb={4}>
        Blog Post Terkait
      </Heading>

      {!loading && data ? (
        <SimpleGrid columns={[1, null, 2, 3]} gap={4}>
          {data.map((post, i) => (
            <BlogPostCard key={i} data={post} />
          ))}
        </SimpleGrid>
      ) : (
        <ComponentSpinner />
      )}
    </VStack>
  );
}
