import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  Text,
  VStack,
  useDisclosure,
  Link as ChakraLink,
  HStack,
  Button,
  Image,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { List } from "@phosphor-icons/react";
import React from "react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import navs from "../const/navs";

export default function NavDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="navDrawer"
        className="btn"
        color={"white"}
        border={"1px solid var(--divider3)"}
        icon={<Icon as={List} />}
        onClick={onOpen}
      />

      <Drawer isOpen={isOpen} onClose={onClose} size={"xs"}>
        <DrawerOverlay backdropFilter={"blur(5px)"} />

        <DrawerContent>
          <DrawerCloseButton
            right={"24px"}
            top={"20px"}
            borderRadius={"full"}
          />

          <DrawerBody p={0}>
            <VStack bg="#111" minH={"100%"} py={6} px={8} align={"stretch"}>
              <HStack w={"203px"}>
                <Image src="/images/logo.png" h={"16px"} />
                <Text className="display" fontWeight={600} mt={"2px"}>
                  Blog Kubu.id
                </Text>
              </HStack>

              <VStack
                gap={8}
                justify={"center"}
                flex={1}
                align={"flex-start"}
                py={16}
              >
                {navs.map((nav, i) => (
                  <Button
                    className="btn-clear"
                    key={i}
                    as={ChakraLink}
                    href={nav.link}
                  >
                    <Text
                      _hover={{ color: "var(--p500)" }}
                      fontSize={32}
                      fontWeight={500}
                      color={"white"}
                      transition={"200ms"}
                    >
                      {nav.name}
                    </Text>
                  </Button>
                ))}
              </VStack>

              {/* <HStack justify={"center"}>
                <ColorModeSwitcher fontSize={20} color={"white"} />
              </HStack> */}

              {/* <VStack align={"flex-start"} mb={10}>
                {landingData.sosmed.map((sosmed, i) => (
                  <Button
                    key={i}
                    aria-label={sosmed.name}
                    leftIcon={
                      <Icon as={sosmed.icon} fontSize={20} color={"white"} />
                    }
                    className="btn"
                    color={"white"}
                  >
                    {sosmed.name}
                  </Button>
                ))}
              </VStack> */}

              <HStack justify={"space-between"}>
                {/* <Button px={6} className="btn-p clicky" color={"white"}>
                  {landingData.hero[lang].contactUs.label}
                </Button> */}

                <ColorModeSwitcher
                  fontSize={20}
                  color={"white"}
                  className="btn"
                />
              </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
