import { ChakraProvider } from "@chakra-ui/react";
import { globalTheme } from "./chakraTheme/globalTheme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./globalStyle.css";
import MissingPage from "./pages/MissingPage";
import Landing from "./pages/Landing";
import AllCategories from "./pages/AllCategories";
import BlogPost from "./pages/BlogPost";
import BlogPostSearch from "./pages/BlogPostSearch";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const App = () => (
  <ChakraProvider theme={globalTheme}>
    <HelmetProvider>
      <Helmet>
        <title>Blog Kubu.id</title>
        <meta
          name="description"
          content="Jelajahi blog menarik seputar dunia kerja di blog.kubu.id"
        />
        <meta property="og:title" content="Blog Kubu.id" />
        <meta
          property="og:description"
          content="Jelajahi blog menarik seputar dunia kerja di blog.kubu.id"
        />
        <meta property="og:image" content="/images/logo.png" />
      </Helmet>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="/categories" element={<AllCategories />} />

          <Route path="/blog/:id" element={<BlogPost />} />

          <Route path="/blog/search" element={<BlogPostSearch />} />

          <Route path="*" element={<MissingPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  </ChakraProvider>
);
