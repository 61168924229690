import {
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Image,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import navs from "../const/navs";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { MagnifyingGlass } from "@phosphor-icons/react";
import useScreenWidth from "../lib/useGetScreenWidth";
import NavDrawer from "./NavDrawer";

type Props = {
  active: number[];
  children: any;
};

export default function NavContainer({ active, children }: Props) {
  const sw = useScreenWidth();

  return (
    <VStack id="navContainer" minH={"100vh"} align={"stretch"} gap={0}>
      <HStack
        zIndex={99}
        position={"fixed"}
        bottom={0}
        left={0}
        w={"100%"}
        justify={"center"}
        px={4}
      >
        <HStack
          // bg={"whiteAlpha.800"}
          bg={"blackAlpha.700"}
          // boxShadow={"0 0 10px 2px var(--divider)"}
          borderRadius={8}
          p={"6px"}
          gap={"6px"}
          mb={4}
          align={"stretch"}
          backdropFilter={"blur(5px)"}
        >
          <Center
            p={"10px"}
            bg={"blackAlpha.700"}
            borderRadius={8}
            w={"50px"}
            as={Link}
            to={"https://kubu.id"}
          >
            <Image
              src={"/images/logo.png"}
              h={"24px"}
              borderRadius={"full"}
              alt="Logo Kubu.id"
            />
          </Center>

          <HStack borderRadius={8} p={"6px"} bg={"blackAlpha.600"}>
            {sw >= 768 && (
              <ColorModeSwitcher
                ml={0}
                color={"white"}
                border={"1px solid var(--divider3)"}
              />
            )}

            <IconButton
              aria-label="iconButton"
              as={Link}
              to={"/blog/search"}
              icon={<Icon as={MagnifyingGlass} />}
              border={
                active.includes(3)
                  ? "1px solid var(--p300)"
                  : "1px solid var(--divider3)"
              }
              className="btn"
              color={active.includes(3) ? "p.300" : "white"}
            />

            {sw >= 768 ? (
              navs.map((nav, i) => {
                const isActive = active.includes(nav.id);

                return (
                  <Button
                    key={i}
                    as={Link}
                    to={nav.link}
                    border={
                      isActive
                        ? "1px solid var(--p300)"
                        : "1px solid var(--divider3)"
                    }
                    className="btn"
                    color={isActive ? "p.300" : "white"}
                  >
                    {nav.name}
                  </Button>
                );
              })
            ) : (
              <NavDrawer />
            )}
          </HStack>
        </HStack>
      </HStack>

      <VStack
        align={"stretch"}
        gap={0}
        // pb={"100px"}
        flex={1}
        id="contentContainer"
      >
        {children}
      </VStack>

      <Footer />
    </VStack>
  );
}
